import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Text, Space, Divider, SearchableSelect, theme, Button, Switch } from 'verak-ui'
import { useEffect, useState } from 'react'

import { useFetch } from '../api/apihooks'
import { useContextApi } from '../context/Provider'
import { useLoadingOverlay } from '../context/LoadingErrorProvider'

import BackArrow from '../assets/icons/BackArrow.svg'

const insurersWithCalculators = [
	'Oriental Insurance',
	'The New India Assurance Co. Ltd.',
	'United India Insurance',
	'National Insurance',
]

const ReviewLeads = ({ displaySuccess }) => {
	const [state, dispatch] = useContextApi()
	let history = useHistory()
	const {
		getOccupancies,
		getOccupancyMapping,
		uploadPreUnderwritingData,
		assignLead,
		getPredictedOccupancy,
	} = useFetch()
	// eslint-disable-next-line
	const { loading, setLoading } = useLoadingOverlay()

	const currentLeadItem = state?.currentLeadItem

	const [updatedOccupancy, setUpdatedOccupancy] = useState([])
	const [insurerOccupancies, setInsurerOccupancies] = useState({})
	const [mappedOccupancies, setMappedOccupancies] = useState({})
	const [predictedOccupancies, setPredictedOccupancies] = useState({})

	// TODO: Make this into a util
	const fetchOccupancies = async () => {
		setLoading({ status: true, loadingText: 'Fetching Insurer Occupancies' })
		const occupancies = await getOccupancies()
		setLoading({ status: false })

		// code to get occupancies in the right format
		let occupanciesInRightFormat = {}
		const insurers = Object.keys(occupancies)
		insurers.forEach(insurer => {
			let data = []
			occupancies[insurer]?.forEach(occupancy => {
				data.push({ name: occupancy, value: occupancy })
			})
			occupanciesInRightFormat[insurer] = data
		})

		dispatch({ type: 'SET_OCCUPANCIES', data: occupanciesInRightFormat })
		setInsurerOccupancies(occupanciesInRightFormat)
	}

	const fetchOccupancyMapping = async () => {
		setLoading({ status: true, loadingText: 'Fetching Occupancy Mappings' })
		const occupancies = await getOccupancyMapping()
		setLoading({ status: false })

		dispatch({ type: 'SET_OCCUPANCY_MAPPING', data: occupancies })
		setMappedOccupancies(occupancies)
	}

	const fetchPredictedOccupancies = async () => {
		// TODO: check this
		const customerResponse =
			currentLeadItem?.Data?.FacilityType + ':- ' + currentLeadItem?.Data?.BusinessType
		setLoading({
			status: true,
			loadingText: `Fetching Predicted Occupnacy for ${customerResponse}`,
		})
		const prediction = await getPredictedOccupancy(customerResponse)
		setLoading({ status: false })

		let updatedList = predictedOccupancies
		updatedList[customerResponse] = prediction.occupancy
		setPredictedOccupancies({ ...predictedOccupancies, [customerResponse]: prediction?.occupancy })
	}
	// Till here

	useEffect(() => {
		if (Object.keys(state?.occupancies)?.length > 0) {
			setInsurerOccupancies(state?.occupancies)
		} else {
			fetchOccupancies()
		}

		if (Object.keys(state?.occupancy_mapping)?.length > 0) {
			setMappedOccupancies(state?.occupancy_mapping)
		} else {
			fetchOccupancyMapping()
		}

		// fetches predicted occupancies for free text responses
		fetchPredictedOccupancies()
		// eslint-disable-next-line
	}, [])

	// TODO: test this when Pre underwriting goes live
	const handleChange = (insurer, val) => {
		const selectedOccupancy = mappedOccupancies[val]
		let updatedValues = []
		if (insurer === 'Oriental Insurance' && selectedOccupancy) {
			insurersWithCalculators.forEach(i => {
				updatedValues.push({ Insurer: i, Value: selectedOccupancy[i] })
			})
		} else {
			updatedValues = updatedOccupancy?.filter(x => x.Insurer !== insurer)
			updatedValues.push({ Insurer: insurer, Value: val })
		}

		setUpdatedOccupancy(updatedValues)
	}

	const goBack = () => history.goBack()

	const handleSubmit = async () => {
		setLoading({ status: true, loadingText: 'Updating Lead details' })

		const result = await uploadPreUnderwritingData(currentLeadItem?.id, updatedOccupancy)

		if (result) {
			await assignLead(currentLeadItem?.id)
			setLoading({ status: false })
			displaySuccess()
		} else {
			setLoading({ status: false })
		}

		// TODO: Storing front testing data for the classifier
	}

	const leadData = {
		id: currentLeadItem?.id,
		Data: currentLeadItem?.Data,
	}

	const headerText = (
		<>
			<div>
				<Text style={primaryHeader}>This Lead needs to be Pre-Underwritten</Text>
				<Space y={1} />
				<Text style={secondaryHeader}>
					To generate an instant quote, this Lead's Occupancy needs to be reviewed <br />
					before being sent to our partner insurance companies.
				</Text>
			</div>

			<Divider />

			<Space y={1} />
			<Text style={primaryHeader}>Missing occupancy!</Text>
			<Space y={1} />
			<Text style={secondaryHeader}>
				The Client has provided the following details along with the occupany of the risk
				location(s) as a free text response. Please apply the correct occupancy with regards to the
				input entered by the Client during the prospecting journey{' '}
			</Text>
		</>
	)

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{currentLeadItem?.id} - {currentLeadItem?.Name} - {currentLeadItem?.CompanyName}
				</div>
			</TopNav>

			<div style={{ padding: '1.5rem' }}>
				{headerText}
				<LeadData
					insurerOccupancies={insurerOccupancies}
					predictedOccupancies={predictedOccupancies}
					leadData={leadData}
					handleChange={handleChange}
					individaulVal={updatedOccupancy}
				/>
				<Space y={1.5} />
				<Button
					label="Approve details"
					style={{ width: '360px', height: '48px' }}
					onClick={handleSubmit}
				/>
				<Space y={3} />
			</div>
		</>
	)
}

export default ReviewLeads

const LeadData = ({
	insurerOccupancies,
	predictedOccupancies,
	leadData,
	handleChange,
	individaulVal,
}) => {
	const questions = [
		{
			question: 'Type of Facility',
			answer: leadData?.Data?.FacilityType,
		},
		{
			question: 'Type of Business',
			answer: leadData?.Data?.BusinessType,
		},
		{
			question: 'Assets to be insured',
			answer: leadData?.Data?.InsuredAssets,
		},
		{
			question: 'Value of assets to be insured',
			answer: leadData?.Data?.AssetValue,
		},
	]

	const insurerDropdowns = insurersWithCalculators.map(i => (
		<SelectInsurerValues
			insurer={i}
			label={i}
			occupancyList={insurerOccupancies[i]}
			data={individaulVal?.find(x => x.Insurer === i)}
			handleChange={handleChange}
			key={i}
		/>
	))

	const customerResponse = leadData?.Data?.FacilityType + ':- ' + leadData?.Data?.BusinessType
	const predictedOccupancy = predictedOccupancies[customerResponse]

	return (
		<StyledContainer>
			{questions.map(x => (
				<Question question={x.question} answer={x.answer} key={x.question} />
			))}
			<Space y={1} />
			<Text style={{ fontSize: '18px' }}>
				Predicted Occupancy:{' '}
				<span style={{ color: `${theme.colors.gray2}`, fontSize: '16px' }}>
					{predictedOccupancy}
				</span>
			</Text>
			<Space x={1} />
			<Button
				label="Approve"
				onClick={() => handleChange('Oriental Insurance', predictedOccupancy)}
			/>
			<Space y={1} />
			{insurerDropdowns}
		</StyledContainer>
	)
}

const SelectInsurerValues = ({ insurer, label, occupancyList, data, handleChange }) => {
	const [isDropdownDisabled, setSwitch] = useState(false)

	// handles the "switch" logic
	useEffect(() => {
		if (isDropdownDisabled) {
			handleChange(insurer, '')
		} // eslint-disable-next-line
	}, [isDropdownDisabled])

	return (
		<>
			<Space y={1.5} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Text style={{ fontSize: '17px' }}>
					{label ? label : insurer}: <br />
					<span style={{ fontSize: '12px', color: `${theme.colors.gray3}` }}>
						Select suitable dropdown value
					</span>
				</Text>
				<Space x={3} />
				<>
					<SearchableSelect
						style={{ width: '100%' }}
						options={occupancyList}
						value={data?.Value}
						onChange={val => handleChange(insurer, val)}
						search
						placeholder="Select"
						disabled={isDropdownDisabled}
					/>
					<Space x={3} />
					<>
						<Switch value={isDropdownDisabled} onChange={() => setSwitch(prev => !prev)} />
						<Space x={1} />
						<Text color={theme.colors.gray3} style={{ fontSize: '11px' }}>
							{isDropdownDisabled ? '' : 'Occupancy not found'}
						</Text>
					</>
				</>
			</div>
		</>
	)
}

const Question = ({ question, answer }) => {
	return (
		<>
			{answer ? (
				<Text style={{ fontSize: '16px', fontWeight: 700 }} color={theme.colors.black}>
					{question}: <span style={{ color: theme.colors.gray3 }}>{answer}</span>
				</Text>
			) : null}
		</>
	)
}

const primaryHeader = {
	fontSize: '24px',
	color: `${theme.colors.primary}`,
}
const secondaryHeader = {
	fontSize: '16px',
	color: `${theme.colors.gray}`,
}

const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
	height: 40px;
`

const StyledContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	width: 90%;
	padding: 1rem;
	margin-top: 1.5rem;
`
