import { useContext, useRef } from 'react'
// import { postReq } from "./API";
import { Context } from '../context/Provider'
import { useAuth0 } from '@auth0/auth0-react'
import { useLoadingOverlay } from '../context/LoadingErrorProvider'

const url = process.env.REACT_APP_SERVER_URL
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export const useFetch = () => {
	const { getAccessTokenSilently } = useAuth0()
	const [state] = useContext(Context)
	const { setError } = useLoadingOverlay()
	const stateRef = useRef()
	stateRef.current = state

	const handleResponse = response => {
		return response.json().then(value => {
			if (!response.ok || value.error) {
				let errorPreview
				if (value.error) {
					errorPreview = value.error
				} else if (!response.ok) {
					errorPreview = response.error ? response.statusText : ''
				}
				setError({
					status: true,
					errorMessage: `	Error: ${errorPreview}`,
					errorStatusCode: response.status,
				})
			} else {
				// setSuccess(true)
			}
			// setLoadingState({ status: false, message: '' })
			return value
		})
	}

	const sendRequest = async (endPoint, type = 'POST', body) => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/${endPoint}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: body ? JSON.stringify(body) : null,
		}).then(handleResponse)

		return result
	}

	const getOccupancies = async () => {
		const result = await sendRequest(`uw/static/occupancies`, 'GET')
		return result
	}

	const getOccupancyMapping = async () => {
		const result = await sendRequest(`uw/static/occupancy_mapping`, 'GET')
		return result
	}

	const getRfqList = async () => {
		const result = await sendRequest(`uw/rfqs`, 'GET')
		return result
	}

	const getRiskLocData = async id => {
		const result = await sendRequest(`uw/rfqs/${id}/risk-loc-data`, 'GET')
		return result
	}

	const uploadReviewedData = async (rfqId, payload) => {
		const result = await sendRequest(`uw/rfqs/${rfqId}`, 'POST', { reviewedData: payload })
		return result
	}

	const assignRfq = async rfqId => {
		const result = await sendRequest(`uw/rfqs/${rfqId}/assign`, 'PUT', {})
		return result
	}

	// Auto-underwriting API's
	const getPredictedOccupancy = async payload => {
		const result = await sendRequest(`uw/predict-occupancy`, 'POST', payload)
		return result
	}

	const sendOccupancyData = async payload => {
		const result = await sendRequest(`uw/classifier-front-test`, 'POST', payload)
		return result
	}

	// Pre-underwriting API's
	const getLeads = async () => {
		// const result = await sendRequest(`uw/leads`, 'GET')
		const result = [
			{
				Data: {
					FacilityType: 'Shop3',
					InsuredAssets: 'Machine3',
					BusinessType: 'Trading',
					AssetValue: '1 crore',
				},
				id: '61fa2f3e97a3d0a183833333',
				Name: 'Krish3',
				CompanyName: 'Krish and Co3',
				Phone: '+918850889628',
				createdAt: '2022-02-02T07:14:06.851Z',
			},
			{
				Data: {
					FacilityType: 'Shop4',
					InsuredAssets: 'Machine4',
				},
				id: '61fa2f3e97a3d0a183844444',
				Name: 'Krish4',
				CompanyName: 'Krish and Co4',
				Phone: '+918850889628',
				createdAt: '2022-02-02T07:14:06.851Z',
			},
		]
		return result
	}

	const uploadPreUnderwritingData = async (leadId, payload) => {
		// const result = await sendRequest(`uw/leads/${leadId}`, 'POST', { reviewedData: payload })
		// return result
		return true
	}

	const assignLead = async leadId => {
		// const result = await sendRequest(`uw/leads/${leadId}/assign`, 'PUT', {})
		// return result
		return
	}

	// Settings API's
	const getLoadingData = async () => {
		const result = await sendRequest(`uw/loading`, 'GET')
		return result
	}

	const updateLoadingData = async payload => {
		const result = await sendRequest(`uw/loading`, 'PUT', payload)
		return result
	}

	return {
		sendRequest,
		getRfqList,
		getRiskLocData,
		getOccupancies,
		getOccupancyMapping,
		uploadReviewedData,
		assignRfq,
		getPredictedOccupancy,
		sendOccupancyData,
		getLeads,
		uploadPreUnderwritingData,
		assignLead,
		getLoadingData,
		updateLoadingData,
	}
}
