import styled from 'styled-components'
// Shared components

// Shared styles
export const TopNav = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: #fff;
	height: 40px;
`
export const StyledContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	width: 90%;
	padding: 1rem;
	margin-top: 1.5rem;
`
