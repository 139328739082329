import { useHistory } from 'react-router'
import {
	Text,
	Space,
	Divider,
	SearchableSelect,
	theme,
	Button,
	Input,
	Switch,
	Icon,
} from 'verak-ui'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useAuth0 } from '@auth0/auth0-react'

import { useFetch } from '../api/apihooks'
import { policyTypeStrings } from '../utils/getPolicyType'
import { useContextApi } from '../context/Provider'
import { useLoadingOverlay } from '../context/LoadingErrorProvider'
import { getShopOccupancies } from '../utils/shopOccupancies'

import { TopNav, StyledContainer } from '../components/common'
import BackArrow from '../assets/icons/BackArrow.svg'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

// ref: https://stackoverflow.com/questions/50795042/create-a-copy-button-without-an-input-text-box/50795833
const copyValue = (event, value, text) => {
	event.stopPropagation()

	const textArea = document.createElement('textarea')
	textArea.value = value
	document.body.appendChild(textArea)
	textArea.select()
	document.execCommand('copy')
	document.body.removeChild(textArea)

	Toast.fire({
		icon: 'success',
		title: `${text} copied to clipboard`,
	})
}

const getUse = occupancy => {
	if (occupancy === 'PrimaryOccupancy') {
		return 'Primary use'
	} else if (occupancy === 'Stock') {
		return 'Nature of stock'
	} else {
		return occupancy
	}
}

const getSalesExecsResponse = (occupancy, locationData, stockIndex) => {
	if (occupancy === 'PrimaryOccupancy') {
		return locationData[occupancy].SubCategory
	} else if (occupancy === 'Stock') {
		return locationData['Stock'][stockIndex]['Type']
	} else {
		return ''
	}
}

const ReviewData = ({ displaySuccess }) => {
	const { user } = useAuth0()

	// refer the backend structure here - under Requirements from backend:
	// https://www.notion.so/Product-Spec-Underwriter-Workbench-c2388f6ed7de4577afdb7bdf0ed1cd62#984d34b72a944231a8536f1848738f3d
	const [state, dispatch] = useContextApi()
	let history = useHistory()
	const {
		getOccupancies,
		getOccupancyMapping,
		uploadReviewedData,
		assignRfq,
		getPredictedOccupancy,
		sendOccupancyData,
	} = useFetch()
	// eslint-disable-next-line
	const { loading, setLoading } = useLoadingOverlay()

	const currentRfqItem = state?.currentRfqItem
	const RiskLocations = currentRfqItem?.RiskLocations
	const isInUWReview = currentRfqItem?.Status === 'UW:REVIEW:PENDING'
	const RFQPdf = currentRfqItem?.Pdf

	const [updatedOccupancy, setUpdatedOccupancy] = useState([])
	const [insurerOccupancies, setInsurerOccupancies] = useState({})
	const [mappedOccupancies, setMappedOccupancies] = useState({})
	const [predictedOccupancies, setPredictedOccupancies] = useState({})

	const fetchOccupancies = async () => {
		setLoading({ status: true, loadingText: 'Fetching Insurer Occupancies' })
		const occupancies = await getOccupancies()
		setLoading({ status: false })

		// code to get occupancies in the right format
		let occupanciesInRightFormat = {}
		const insurers = Object.keys(occupancies)
		insurers.forEach(insurer => {
			let data = []
			occupancies[insurer]?.forEach(occupancy => {
				data.push({ name: occupancy, value: occupancy })
			})
			occupanciesInRightFormat[insurer] = data
		})

		dispatch({ type: 'SET_OCCUPANCIES', data: occupanciesInRightFormat })
		setInsurerOccupancies(occupanciesInRightFormat)
	}

	const fetchOccupancyMapping = async () => {
		setLoading({ status: true, loadingText: 'Fetching Occupancy Mappings' })
		const occupancies = await getOccupancyMapping()
		setLoading({ status: false })

		dispatch({ type: 'SET_OCCUPANCY_MAPPING', data: occupancies })
		setMappedOccupancies(occupancies)
	}

	const fetchPredictedOccupancies = async () => {
		const data = currentRfqItem?.RiskLocations?.map(rl => {
			const stockValues = rl?.Stock.map(x => x?.Type)
			return { occupancy: rl?.PrimaryOccupancy?.SubCategory, stock: stockValues?.join(' ') }
		})
		for (const x of data) {
			setLoading({ status: true, loadingText: `Fetching Predicted Occupnacy for ${x.occupancy}` })
			const payload = {
				text: x.occupancy,
				natureOfStock: x.stock,
			}
			const prediction = await getPredictedOccupancy(payload)
			setLoading({ status: false })

			let updatedList = predictedOccupancies
			updatedList[x.occupancy] = prediction.occupancy
			setPredictedOccupancies({ ...predictedOccupancies, [x.occupancy]: prediction?.occupancy })
		}
	}

	useEffect(() => {
		// sets insurer occupancies for the dropdowns
		if (Object.keys(state?.occupancies)?.length > 0) {
			setInsurerOccupancies(state?.occupancies)
		} else {
			fetchOccupancies()
		}

		// sets mapping between various insurer occupancies -> responsible for the prefilling of other dropdowns
		if (Object.keys(state?.occupancy_mapping)?.length > 0) {
			setMappedOccupancies(state?.occupancy_mapping)
		} else {
			fetchOccupancyMapping()
		}

		// fetches predicted occupancies for free text responses
		if (isInUWReview) {
			fetchPredictedOccupancies()
		}
		// eslint-disable-next-line
	}, [])

	const handleRLUpdate = occupancyVals => {
		const updatedValues = updatedOccupancy?.filter(occupancy => occupancy?.ID !== occupancyVals?.ID)
		updatedValues.push(occupancyVals)
		setUpdatedOccupancy(updatedValues)
	}

	const goBack = () => history.goBack()

	const pdfEle = RFQPdf ? (
		<a href={RFQPdf} target="blank">
			<Button label="View RFQ PDF!" style={{ marginRight: '5rem' }} />
		</a>
	) : null

	let headerText = (
		<>
			<div className="full-flex-container">
				<Text style={primaryHeader}>This RFQ has been reviewed!</Text>
				{pdfEle}
			</div>
		</>
	)
	if (isInUWReview) {
		headerText = (
			<>
				<div className="full-flex-container">
					<div>
						<Text style={primaryHeader}>This RFQ needs a review</Text>
						<Space y={1} />
						<Text style={secondaryHeader}>
							To reduce errors and RFQ rejections, this RFQ's Occupancy needs to be reviewed <br />
							before being sent to our partner insurance companies.
						</Text>
					</div>
					{pdfEle}
				</div>

				<Divider />

				<Space y={1} />
				<Text style={primaryHeader}>Missing occupancy!</Text>
				<Space y={1} />
				<Text style={secondaryHeader}>
					The sales executive has chosen to provide the occupany of the risk location(s) as a free
					text response. Please apply the correct occupancy with regards to the input entered by the
					sales executive{' '}
				</Text>
			</>
		)
	}

	const handleSubmit = async () => {
		setLoading({ status: true, loadingText: 'Updating RFQ details' })

		let updatedData = []

		// copy Reliance value into Royal Sundaram - Primary Occupancy
		updatedOccupancy.forEach(location => {
			let locationData = { ID: location?.ID }
			if (location?.Stock) {
				locationData['Stock'] = location?.Stock
			}

			const correctedOccupancy = location?.PrimaryOccupancy?.Alt.find(
				i => i.Insurer === 'Reliance General Insurance'
			)?.Value
			const insurersWithFreeText = ['Kotak General Insurance', 'Royal Sundaram General Insurance']
			const freeTextOccupancies = insurersWithFreeText.map(i => {
				return {
					Insurer: i,
					Value: correctedOccupancy,
				}
			})
			let primaryOccupancies = {
				Alt: [
					...location?.PrimaryOccupancy?.Alt,
					...freeTextOccupancies,
					{
						Insurer: 'Bajaj Allianz General Insurance',
						Value:
							location?.PrimaryOccupancy?.Alt.find(i => i.Insurer === 'Oriental Insurance')
								?.Value || '',
					},
				],
			}
			locationData['PrimaryOccupancy'] = primaryOccupancies
			updatedData.push(locationData)
		})

		// Storing front testing data for the classifier -> only sends data for actual UW's
		const underwriters = ['abhaya@verak.in', 'rishabh@verak.in']
		if (underwriters.includes(user?.email)) {
			const occupancies = currentRfqItem?.RiskLocations?.map(rl => {
				return {
					ID: rl?.ID,
					Occupancy: rl?.PrimaryOccupancy?.SubCategory,
				}
			})
			for (const rl of updatedData) {
				const loc = occupancies.find(i => i.ID === rl.ID)
				const payload = {
					rfqId: currentRfqItem?.id,
					text: loc.Occupancy,
					predictedOccupancy: predictedOccupancies[loc.Occupancy],
					correctOccupancy: rl?.PrimaryOccupancy?.Alt.find(i => i.Insurer === 'Oriental Insurance')
						?.Value,
				}
				await sendOccupancyData(payload)
			}
		}

		// Updating and Assigning the RFQ
		const result = await uploadReviewedData(currentRfqItem?.id, updatedData)
		if (!result.error) {
			await assignRfq(currentRfqItem?.id)
			setLoading({ status: false })
			displaySuccess()
		} else {
			setLoading({ status: false })
		}
	}

	return (
		<>
			<TopNav>
				<span style={{ cursor: 'pointer' }} onClick={goBack}>
					<img src={BackArrow} alt="back arrow" />
				</span>
				<div
					className="mx-3"
					style={{ fontSize: '18px', lineHeight: '23px', color: '#02475E', fontWeight: '700' }}
				>
					{policyTypeStrings(currentRfqItem?.Tag)}, {currentRfqItem?.id} - {currentRfqItem?.Name}
				</div>
			</TopNav>

			<div className="overflow-auto p-4">
				{headerText}
				{RiskLocations?.map((location, index) => (
					<LocationData
						insurerOccupancies={insurerOccupancies}
						mappedOccupancies={mappedOccupancies}
						predictedOccupancies={predictedOccupancies}
						locationData={location}
						handleRLUpdate={handleRLUpdate}
						rlIndex={index + 1}
						isInUWReview={isInUWReview}
						key={index}
					/>
				))}

				<Space y={1.5} />
				{isInUWReview ? (
					<Button
						label="Approve details"
						style={{ width: '360px', height: '48px' }}
						onClick={handleSubmit}
					/>
				) : null}

				<Space y={3} />
			</div>
		</>
	)
}

export default ReviewData

const LocationData = ({
	insurerOccupancies,
	mappedOccupancies,
	predictedOccupancies,
	locationData,
	handleRLUpdate,
	rlIndex,
	isInUWReview,
}) => {
	let stockDetailsList = locationData?.Stock

	let initialData = {
		ID: locationData?.ID,
		PrimaryOccupancy: locationData?.PrimaryOccupancy || {},
	}
	if (locationData?.Stock) {
		initialData.Stock = stockDetailsList || []
	}

	const [data, setData] = useState(initialData)

	const handleOccupancy = (occupancy, insurer, value) => {
		// Note: "occupancy" here refers to PrimaryOccupancy or SecondaryOccupancy
		const prevOccupancyVals = data[occupancy]?.Alt || []

		const selectedOccupancy = mappedOccupancies[value]
		if (insurer === 'Oriental Insurance' && selectedOccupancy) {
			// update all the remaining insurers from "mapped occupancies"
			Object.keys(selectedOccupancy).forEach(i => {
				let insurerSelected = prevOccupancyVals?.find(x => x.Insurer === i)
				const occupancyForInsurerSelected = selectedOccupancy[i]
				if (insurerSelected) {
					insurerSelected.Value = occupancyForInsurerSelected
				} else {
					prevOccupancyVals.push({ Insurer: i, Value: occupancyForInsurerSelected })
				}
			})
		} else {
			let currentOccupancy = prevOccupancyVals?.find(i => i.Insurer === insurer)
			if (currentOccupancy) {
				currentOccupancy.Value = value // already exists case
			} else {
				prevOccupancyVals.push({ Insurer: insurer, Value: value })
			}
		}

		const updatedValues = {
			...data,
			[occupancy]: {
				Alt: prevOccupancyVals,
			},
		}
		setData(updatedValues)
		handleRLUpdate(updatedValues)
	}

	const handleStock = (value, stockIndex, stockType) => {
		let updatedValues = {}
		let stockDetails = data.Stock || []

		stockDetails[stockIndex] = {
			Type: stockType,
			Alt: value,
		}
		updatedValues = {
			...data,
			Stock: stockDetails,
		}
		setData(updatedValues)
		handleRLUpdate(updatedValues)
	}

	const handleShopType = (occupancy, insurer, value) => {
		const prevOccupancyVals = data[occupancy]?.Alt || []
		let currentOccupancy = prevOccupancyVals?.find(i => i.Insurer === insurer)
		if (currentOccupancy) {
			// Reason: Shop type shouldn't exist without a primary occupancy attached
			currentOccupancy.ShopType = value
			const updatedValues = {
				...data,
				[occupancy]: {
					Alt: prevOccupancyVals,
				},
			}
			setData(updatedValues)
			handleRLUpdate(updatedValues)
		}
	}

	return (
		<>
			<SingleOccupancy
				insurerOccupancies={insurerOccupancies}
				predictedOccupancies={predictedOccupancies}
				occupancy="PrimaryOccupancy"
				handleData={handleOccupancy}
				handleShopType={handleShopType}
				individaulVal={data['PrimaryOccupancy']?.Alt}
				locationData={locationData}
				rlIndex={rlIndex}
				isInUWReview={isInUWReview}
			/>
			{stockDetailsList?.map((stockData, index) => (
				<SingleOccupancy
					insurerOccupancies={insurerOccupancies}
					predictedOccupancies={predictedOccupancies}
					occupancy="Stock"
					handleData={handleStock}
					individaulVal={data.Stock[index]?.Alt}
					locationData={locationData}
					stockType={stockData?.Type}
					rlIndex={rlIndex}
					stockIndex={index}
					key={index}
				/>
			))}
		</>
	)
}

const SingleOccupancy = ({
	insurerOccupancies,
	predictedOccupancies,
	occupancy,
	handleData,
	handleShopType,
	individaulVal,
	locationData,
	stockType,
	rlIndex,
	stockIndex,
	isInUWReview,
}) => {
	const salesExecsResponse = getSalesExecsResponse(occupancy, locationData, stockIndex)
	const predictedOccupancy = predictedOccupancies[salesExecsResponse]

	return (
		<StyledContainer>
			<Text style={{ fontSize: '18px', fontWeight: 700 }} color={theme.colors.secondary}>
				{getUse(occupancy)} at Risk Location {rlIndex} - Floor/Level {locationData.Level} - Pin -{' '}
				{locationData.PIN}
			</Text>
			<Space y={1} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Text style={{ fontSize: '18px' }}>
					Response from sales executive:{' '}
					<span style={{ color: `${theme.colors.gray2}`, fontSize: '16px' }}>
						{salesExecsResponse}
					</span>
				</Text>
				<Space x={1} />
				<ClipboardCTA copyValue={copyValue} label={salesExecsResponse} toastText="Response" />
			</div>
			<Space y={1.5} />

			{isInUWReview && occupancy !== 'Stock' ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Text style={{ fontSize: '18px' }}>
						Predicted Occupancy:{' '}
						<span style={{ color: `${theme.colors.gray2}`, fontSize: '16px' }}>
							{predictedOccupancy}
						</span>
					</Text>
					<Space x={1} />
					<Button
						label="Approve"
						onClick={() => handleData(occupancy, 'Oriental Insurance', predictedOccupancy)}
					/>
				</div>
			) : null}

			{occupancy === 'Stock' ? (
				<SelectInsurerValues
					label="Corrected Nature of Stock"
					data={individaulVal}
					handleChange={handleData}
					occupancy="Stock"
					space={1}
					stockIndex={stockIndex}
					stockType={stockType}
				/>
			) : (
				<>
					<SelectInsurerValues
						insurer="Oriental Insurance"
						label="Oriental / Bajaj Allianz"
						occupancyList={insurerOccupancies['Oriental Insurance']}
						data={individaulVal?.find(i => i.Insurer === 'Oriental Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="The New India Assurance Co. Ltd."
						label="New India"
						occupancyList={insurerOccupancies['The New India Assurance Co. Ltd.']}
						data={individaulVal?.find(i => i.Insurer === 'The New India Assurance Co. Ltd.')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="United India Insurance"
						label="United"
						occupancyList={insurerOccupancies['United India Insurance']}
						data={individaulVal?.find(i => i.Insurer === 'United India Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="National Insurance"
						label="National"
						occupancyList={insurerOccupancies['National Insurance']}
						data={individaulVal?.find(i => i.Insurer === 'National Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="ICICI Lombard General Insurance"
						label="ICICI"
						occupancyList={insurerOccupancies['ICICI Lombard General Insurance']}
						data={individaulVal?.find(i => i.Insurer === 'ICICI Lombard General Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="Digit Insurance"
						label="Digit"
						occupancyList={insurerOccupancies['Digit Insurance']}
						data={individaulVal?.find(i => i.Insurer === 'Digit Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="Liberty General Insurance"
						label="Liberty"
						occupancyList={insurerOccupancies['Liberty General Insurance']}
						shopList={insurerOccupancies['Liberty General Insurance Type Of Shop']}
						handleShopType={handleShopType}
						data={individaulVal?.find(i => i.Insurer === 'Liberty General Insurance')}
						handleChange={handleData}
						occupancy={occupancy}
						space={3}
						isDropdown={true}
						stockIndex={stockIndex}
						stockType={stockType}
						isInUWReview={isInUWReview}
					/>
					<SelectInsurerValues
						insurer="Reliance General Insurance"
						label="Reliance / Kotak / Royal Sundaram"
						data={individaulVal?.find(i => i.Insurer === 'Reliance General Insurance')} // "|| salesExecsResponse" for prefilling -> then handle auto update
						handleChange={handleData}
						occupancy={occupancy}
						space={1}
						stockIndex={stockIndex}
						stockType={stockType}
					/>
				</>
			)}
		</StyledContainer>
	)
}

const SelectInsurerValues = ({
	insurer,
	label,
	occupancyList,
	shopList,
	handleShopType = () => {},
	data,
	handleChange,
	occupancy,
	isDropdown,
	space,
	stockIndex,
	stockType,
	isInUWReview,
}) => {
	const handleDropdownSwitch = () => {
		if (isInUWReview) {
			return false
		} else {
			if (data?.Value) {
				return false
			}
			return true
		}
	}

	const [isDropdownDisabled, setSwitch] = useState(handleDropdownSwitch)
	const [shopType, setShopType] = useState(false)

	// handles the "switch" logic
	useEffect(() => {
		if (isDropdownDisabled && occupancy !== 'Stock') {
			handleChange(occupancy, insurer, '', stockIndex, stockType)
			handleShopType(occupancy, insurer, '')
		} // eslint-disable-next-line
	}, [isDropdownDisabled])

	// handles Shop Dropdown
	useEffect(() => {
		if (getShopOccupancies(insurer)?.includes(data?.Value)) {
			setShopType(true)
		} else {
			setShopType(false)
		} // eslint-disable-next-line
	}, [data?.Value])

	return (
		<>
			<Space y={1.5} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Text style={{ fontSize: '17px' }}>
					{label ? label : insurer}: <br />
					<span style={{ fontSize: '12px', color: `${theme.colors.gray3}` }}>
						{isDropdown ? 'Select suitable dropdown value' : 'Edit free text response'}
					</span>
				</Text>
				<Space x={space} />
				{isDropdown ? (
					<>
						<SearchableSelect
							style={{ width: '100%' }}
							options={occupancyList}
							value={data?.Value}
							onChange={val => handleChange(occupancy, insurer, val, stockIndex, stockType)}
							search
							placeholder="Select"
							disabled={isDropdownDisabled}
						/>

						{shopType ? (
							<>
								<Space x={3} />
								<SearchableSelect
									style={{ width: '100%' }}
									options={shopList}
									value={data?.ShopType}
									onChange={val => handleShopType(occupancy, insurer, val)}
									search
									placeholder="Select"
									disabled={isDropdownDisabled}
								/>
							</>
						) : null}

						<Space x={3} />
						{isInUWReview ? (
							<>
								<Switch value={isDropdownDisabled} onChange={() => setSwitch(prev => !prev)} />
								<Space x={1} />
								<Text color={theme.colors.gray3} style={{ fontSize: '11px' }}>
									{isDropdownDisabled ? '' : 'Occupancy not found'}
								</Text>
							</>
						) : null}
					</>
				) : (
					<Input
						type="text"
						placeholder="Enter here"
						value={occupancy === 'Stock' ? data : data?.Value}
						onChange={e => {
							occupancy === 'Stock'
								? handleChange(e.target.value, stockIndex, stockType)
								: handleChange(occupancy, insurer, e.target.value)
						}}
					/>
				)}
			</div>
		</>
	)
}

const ClipboardCTA = ({ copyValue, label, toastText }) => {
	return (
		<div
			onClick={e => {
				copyValue(e, label, toastText)
			}}
			style={{ cursor: 'pointer' }}
		>
			<Icon name="copyToClipboard" />
		</div>
	)
}

const primaryHeader = {
	fontSize: '24px',
	color: `${theme.colors.primary}`,
}
const secondaryHeader = {
	fontSize: '16px',
	color: `${theme.colors.gray}`,
}
