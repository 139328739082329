import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { theme } from 'verak-ui'

import { useContextApi } from '../context/Provider'

import profilePic from '../assets/icons/profilePic.svg'
import Home from '../assets/icons/Home.svg'
import settingsIcon from '../assets/icons/settings.svg'

export default function SidePanel() {
	// eslint-disable-next-line
	const [state, dispatch] = useContextApi()
	const history = useHistory()
	const navigateToReview = () => {
		history.replace('/')
	}
	const navigateToSettings = () => {
		history.replace('/loading')
	}

	return (
		<SidePanelContainer className="vertical-flex-container">
			<Section $bottomborder={true} onClick={navigateToReview}>
				<img className="mb-2" src={Home} alt="Home" />
				<p>Underwriting</p>
			</Section>
			<Section $bottomborder={true} onClick={navigateToSettings}>
				<img className="mb-2" src={settingsIcon} alt="Home" />
				<p>Loading</p>
			</Section>
			{state.currentRfqItem?.InitiatedBy && (
				<Section>
					<img className="mb-2" src={profilePic} alt="profilePic" />
					<p>OPS MANAGER</p>
					<p>{state.currentRfqItem.InitiatedBy}</p>
				</Section>
			)}
			<div className="flex-fill" />
			{state.currentRfqItem?.Agent && (
				<Section>
					<img className="mb-2" src={profilePic} alt="profilePic" />
					<p>RFQ BY</p>
					<p>{state.currentRfqItem.Agent.name}</p>
				</Section>
			)}
		</SidePanelContainer>
	)
}

const Section = styled.div`
	padding: 10px 0;
	width: 80px;
	min-height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	border-bottom: ${props => (props.$bottomborder ? 'solid 1px #c4c4c4' : '')};
	p {
		text-align: center;
		padding: 0;
		font-size: 10px;
		font-weight: 700;
		color: ${props => (props.selected ? 'white' : theme.colors.gray4)};
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}
	p:last-child {
		margin-bottom: 0;
	}
	background-color: ${props => (props.selected ? '#e47034' : 'initial')};
`

const SidePanelContainer = styled.div`
	position: fixed;
	left: 0;
	top: 64px;
	width: 80px;
	height: calc(100% - 64px);
	background-color: #fff;
	box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.05);
`
