export const policyTypeStrings = Tag => {
	let uppercaseTag = Tag?.toUpperCase()
	switch (uppercaseTag) {
		case 'FIRE':
			return 'Fire & Allied Perils'
		case 'FIRE_ALL_RISK':
			return 'Fire All Risk'
		default:
			return uppercaseTag
	}
}