import { Navbar, NavDropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

import Logo from '../assets/logomark.svg'

const navStyles = {
	height: '64px',
	boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
}

export default function NavigationBar({ isAuthenticated }) {
	const { user, logout } = useAuth0()

	let executiveName = user?.name || 'Executive Name'

	const handleLogout = () => {
		logout({
			returnTo: `${window.location.origin}/logout`,
		})
	}

	return (
		<Navbar className="custom-navbar" style={navStyles}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<LogoBox className="flex-container">
						<img src={Logo} alt="Logo" />
					</LogoBox>
					<BrandHeading>VERAK OPERATIONS</BrandHeading>
				</div>

				{isAuthenticated && (
					<Navbar.Collapse id="basic-navbar-nav">
						<Navbar.Collapse className="flex-container">
							<NavDropdown
								style={{
									color: '#03045E !important',
									fontSize: '14px',
									fontWeight: '400',
									marginRight: '1rem',
								}}
								title={executiveName}
								id="basic-nav-dropdown"
							>
								<NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
							</NavDropdown>
						</Navbar.Collapse>
					</Navbar.Collapse>
				)}
			</div>
		</Navbar>
	)
}

const BrandHeading = styled(Navbar.Brand)`
	color: #03045e !important;
	font-size: 14px;
	font-weight: 400;
	padding-left: 2rem;
`

const LogoBox = styled.div`
	width: 80px;
	height: 64px;
	background: #03045e;
`
