import { useEffect, useState } from 'react'
import { Text, Input, Space, Divider, theme, Button } from 'verak-ui'

import { getDateString } from '../utils/dateUtils'
import { StyledContainer } from '../components/common'
import { useFetch } from '../api/apihooks'

const INSURERS_WITH_CALCS = [
	'Oriental Insurance',
	// 'The New India Assurance Co. Ltd.',
	'United India Insurance',
	'National Insurance',
]

const Settings = ({ displaySuccess }) => {
	const { getLoadingData, updateLoadingData } = useFetch()

	const [prevInsurerData, setPrevInsurerData] = useState([])
	const [newLoadingVals, setNewLoadingVals] = useState([])
	const [loadingError, setLoadingError] = useState(false)

	const fetchInsurerData = async () => {
		const result = await getLoadingData()
		setPrevInsurerData(result)
	}
	useEffect(() => {
		fetchInsurerData()
		// eslint-disable-next-line
	}, [])

	const handleNewLoadingVals = (insurer, val) => {
		if (val < 0 || val > 100) {
			setLoadingError(true)
		} else {
			setLoadingError(false)
		}
		let updatedList = [...newLoadingVals]
		updatedList = updatedList.filter(i => i.insurer !== insurer)
		updatedList.push({ insurer: insurer, rate: parseInt(val) })
		setNewLoadingVals(updatedList)
	}

	const handleSubmit = async () => {
		// filters out NaN one's
		let updatedVals = [...newLoadingVals]
		updatedVals = updatedVals.filter(x => !isNaN(x.rate))

		// check to send only "changed/altered" Loading values
		let finalVals = []
		updatedVals.forEach(x => {
			const prevValues = prevInsurerData.find(i => i.name === x.insurer)?.loading
			if (prevValues?.rate !== x.rate) {
				finalVals.push(x)
			}
		})

		for (const x of finalVals) {
			// Makes the API call sending finalVals
			// eslint-disable-next-line
			const result = await updateLoadingData(x)
		}

		// Takes you to the success screen
		displaySuccess()
	}

	const loadingErrorComp = loadingError ? (
		<Text color={theme.colors.red}>Please enter values between 0 and 100 (both included) only</Text>
	) : null

	return (
		<div style={{ padding: '1.5rem', paddingTop: 0 }}>
			<StyledContainer>
				<Text style={{ fontSize: '18px', fontWeight: 700 }}>Set Loading:-</Text>
				<Divider top={1} bottom={0} />
				{INSURERS_WITH_CALCS.map(i => (
					<LoadingComponent
						prevInsurerData={prevInsurerData}
						insurer={i}
						handleNewLoadingVals={handleNewLoadingVals}
						key={i}
					/>
				))}
				<Space y={1.5} />
				<Button label="Submit" onClick={handleSubmit} disabled={loadingError} />
				<Space y={0.5} />
				{loadingErrorComp}
			</StyledContainer>
		</div>
	)
}

const LoadingComponent = ({ prevInsurerData, insurer, handleNewLoadingVals }) => {
	const data = prevInsurerData.find(x => x.name === insurer)?.loading

	let prevValue = data?.rate
	if (prevValue === 0) {
		// hacky way to not consider 0 as a boolean
		prevValue = '0'
	}
	const updatedBy = data?.updatedBy?.name
	let updatedAt = data?.updatedAt
	if (updatedAt) {
		updatedAt = getDateString(updatedAt)
	}
	return (
		<>
			<Space y={1.5} />
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>{insurer}</Text>
			<Space y={0.5} />
			<InfoComponent label="Current Value" value={prevValue} />
			<InfoComponent label="Updated By" value={updatedBy} />
			<InfoComponent label="Updated At" value={updatedAt} />
			<Space y={0.5} />
			<Input
				type="number"
				placeholder="Enter here"
				value={insurer?.Loading}
				onChange={e => handleNewLoadingVals(insurer, e.target.value)}
			/>
		</>
	)
}

const InfoComponent = ({ label, value }) => {
	return (
		<>
			{value ? (
				<Text style={{ fontSize: '14px', fontWeight: 700 }}>
					{label}: &nbsp;&nbsp;{value}
				</Text>
			) : null}
		</>
	)
}

export default Settings
