export const reducer = (state, action) => {
	switch (action.type) {
		case 'ADD_RFQS':
			return {
				...state,
				rfqList: [...action.data],
			}
		case 'SELECT_RFQ':
			return {
				...state,
				currentRfqItem: {
					...action.data,
				},
			}
		case 'SET_OCCUPANCIES':
			return {
				...state,
				occupancies: {
					...action.data,
				},
			}
		case 'SET_OCCUPANCY_MAPPING':
			return {
				...state,
				occupancy_mapping: {
					...action.data,
				},
			}
		case 'ADD_LEADS':
			return {
				...state,
				leads: [...action.data],
			}
		case 'SELECT_LEAD':
			return {
				...state,
				currentLeadItem: {
					...action.data,
				},
			}
		default:
			return state
	}
}
