import { Icon, Text, theme, Tooltip } from 'verak-ui'
import styled from 'styled-components'

const FilterButton = ({ activeFilter, setFilterValue, menuItems, disableLeftMargin }) => {
	const menuItemsMap = menuItems.map((menuItem, index) => {
		const isLast = index !== menuItems.length - 1

		const clickHandler = () => {
			if (menuItem.disabled === false && activeFilter !== menuItem.value) {
				setFilterValue(menuItem.value)
			}
		}

		return (
			<MenuItemContainer onClick={clickHandler} isLast={isLast} key={`menu-item-${menuItem.text}`}>
				{menuItem.text}
			</MenuItemContainer>
		)
	})

	return (
		<>
			<FilterButtonWrapper
				contentStyle={{ padding: '0' }}
				html={<MenuContainer>{menuItemsMap}</MenuContainer>}
				theme="light"
				trigger="click"
				position="bottom"
				disableLeftMargin={disableLeftMargin}
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Text color={theme.colors.gray3} fontWeight="400" fontSize="14px">
						{activeFilter}
					</Text>

					<Icon
						name="rightArrow"
						color={theme.colors.gray3}
						style={{ transform: 'rotate(90deg)' }}
					/>
				</div>
			</FilterButtonWrapper>
		</>
	)
}

export default FilterButton

// styles
const FilterButtonWrapper = styled(Tooltip)`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 8px;
	cursor: pointer;
	height: 48px;
	margin-left: 10px;
	width: 170px;
	@media (max-width: 768px) {
		width: 100%;
		margin-left: ${({ disableLeftMargin }) => (disableLeftMargin ? '0px' : '10px')};
	}
`

const MenuItemContainer = styled.li`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.375rem;
	border-bottom: ${props => (props?.isLast ? '0.5px solid #8d8d8d' : 'none')};
	list-style: none;
	background-color: ${({ disabled }) => (disabled ? '#e3e3e3' : '#fff')};
	opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0rem;
	color: #8d8d8d;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	&:hover {
		background: ${({ disabled }) => (disabled ? '' : ' #e9e9e9')};
	}
`

const MenuContainer = styled.ul`
	min-width: 200px;
	padding: 0;
`
