export const getShopOccupancies = insurer => {
	switch (insurer) {
		case 'Liberty General Insurance':
			return [
				'Shops dealing in goods otherwise not provided for',
				'Shop Cum Godown (Storage Of Non Hazardous Goods',
			]
		default:
			return []
	}
}
