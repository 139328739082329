import { useState } from 'react'
import Success from '../../components/Success'
import Settings from '../Settings'

const SettingsHandler = () => {
	const [pageToRender, setPageToRender] = useState('Settings')
	const displaySuccess = () => setPageToRender('Success')

	let renderCurrentPage = null
	if (pageToRender === 'Settings') {
		renderCurrentPage = <Settings displaySuccess={displaySuccess} />
	} else if (pageToRender === 'Success') {
		renderCurrentPage = (
			<Success continueButtonLink="/underwriting" ctaText="Continue to Reviewing RFQ's" />
		)
	}

	return renderCurrentPage
}

export default SettingsHandler
