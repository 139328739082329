import { useState } from 'react'
import Success from '../../components/Success'
import ReviewLeads from '../ReviewLeads'

const PreUnderwritingHandler = () => {
	const [pageToRender, setPageToRender] = useState('ReviewLeads')
	const displaySuccess = () => setPageToRender('ReviewSuccess')

	let renderCurrentPage = null
	if (pageToRender === 'ReviewLeads') {
		renderCurrentPage = <ReviewLeads displaySuccess={displaySuccess} />
	} else if (pageToRender === 'ReviewSuccess') {
		renderCurrentPage = (
			<Success continueButtonLink="/" ctaText="Continue to Pre-Underwriting RFQ's" />
		)
	}

	return renderCurrentPage
}

export default PreUnderwritingHandler
