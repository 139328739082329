import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

import SidePanel from './components/SidePanel'
import Navbar from './components/Navbar'

import Home from './screens/Home'
import PreUnderwritingHandler from './screens/Handlers/PreUnderwritingHandler'
import ReviewHandler from './screens/Handlers/ReviewHandler'
import UnderwriterHome from './screens/UnderwriterHome'
import SettingsHandler from './screens/Handlers/SettingsHandler'

// context
import LoadingOverlay from './context/LoadingErrorProvider'
import { LoadingOverlayProvider } from './context/LoadingErrorProvider'

import ProtectedRoute from './auth/protected-route'

function App() {
	const { isAuthenticated } = useAuth0()
	return (
		<BrowserRouter>
			<LoadingOverlayProvider>
				<SidePanel />
				<Navbar isAuthenticated={isAuthenticated} />
				<AppContainer>
					<Switch>
						<Route path="/" exact={true} component={Home} />
						<Route path="/logout" exact={true} component={Home} />
						<ProtectedRoute
							path="/pre-underwriting"
							exact={true}
							component={PreUnderwritingHandler}
						/>
						<ProtectedRoute path="/underwriting" exact={true} component={UnderwriterHome} />
						<ProtectedRoute path="/review-data" exact={true} component={ReviewHandler} />
						<ProtectedRoute path="/loading" exact={true} component={SettingsHandler} />
					</Switch>
				</AppContainer>
				<LoadingOverlay />
			</LoadingOverlayProvider>
		</BrowserRouter>
	)
}
export default App

const AppContainer = styled.div`
	padding: 0 0 0 80px;
	height: Calc(100% - 64px);
	display: flex;
	flex-direction: column;
`
