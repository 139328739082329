import { Table, FormControl } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { theme, Text } from 'verak-ui'

import { useFetch } from '../api/apihooks'
import { useContextApi } from '../context/Provider'
import { getDateString } from '../utils/dateUtils'
import { useLoadingOverlay } from '../context/LoadingErrorProvider'
import FilterButton from '../components/FilterButton'

import ChevronRight from '../assets/icons/ChevronRight.svg'

const statusStrings = status => {
	switch (status) {
		case 'UW:REVIEW:PENDING':
			return 'Review Pending'
		default:
			return 'Review Completed!'
	}
}

const statusFilterMenuItems = [
	{ text: 'None', value: '', disabled: false },
	{ disabled: false, text: 'Review Pending', value: 'Review Pending' },
	{ disabled: false, text: 'Review Completed!', value: 'Review Completed!' },
]

// TODO: add subsequent tags like - 1) Abandoned and case lost, 2) new payment statuses which came into effect with Razorpay
const tagsToBeFiltered = ['AGENT:RFQ:PENDING', 'OPS:POLICY:PENDING', 'OPS:CLOSURE:PENDING']

const UnderwriterHome = () => {
	const { getRfqList, getRiskLocData } = useFetch()
	const { loading, setLoading } = useLoadingOverlay()
	const [state, dispatch] = useContextApi()
	let history = useHistory()

	const [searchQuery, setSearchQuery] = useState('')
	const [statusFilter, setStatusFilter] = useState('')
	const setFilterValue = v => setStatusFilter(v)

	const fetchRFQList = async () => {
		setLoading({ status: true, loadingText: "Loading RFQ's" })
		const result = await getRfqList()
		dispatch({ type: 'ADD_RFQS', data: result })
		setLoading({ status: false })
	}

	const handleRowClick = async (rfqItem, id) => {
		setLoading({ status: true, loadingText: 'Fetching Risk Location Data' })
		const result = await getRiskLocData(id)

		const rfqData = {
			...result,
			...rfqItem,
		}

		await new Promise(resolve => {
			dispatch({
				type: 'SELECT_RFQ',
				data: rfqData,
			})
			resolve()
		})
		setLoading({ status: false })

		history.push('/review-data')
	}

	useEffect(() => {
		fetchRFQList()

		// resets currentRfqItem
		dispatch({ type: 'SELECT_RFQ', data: {} })
		// eslint-disable-next-line
	}, [])

	const getReviewedBy = item => {
		let reviewedBy
		if (item?.Status === 'UW:REVIEW:PENDING') {
			if (item?.ReviewedBy) {
				reviewedBy = <Text>{item?.ReviewedBy}</Text>
			} else {
				reviewedBy = <Text color={theme.colors.red}>Click to start!</Text>
			}
		} else {
			reviewedBy = <Text>{item?.ReviewedBy || `Automated`}</Text>
		}
		return reviewedBy
	}

	const getTableRow = item => {
		return (
			<TableRow onClick={() => handleRowClick(item, item.id)} key={item.id}>
				<TableDataItem>{item.Name}</TableDataItem>
				<TableDataItem>{item.Phone}</TableDataItem>
				<TableDataItem>{getDateString(item.updatedAt)}</TableDataItem>
				<TableDataItem>{getReviewedBy(item)}</TableDataItem>
				<TableDataItem>{item.id}</TableDataItem>
				<TableDataItem>
					<Tag status={statusStrings(item.Status)} />
				</TableDataItem>
				<TableDataItem>
					<img className="pe-4" src={ChevronRight} alt="right arrow" />
				</TableDataItem>
			</TableRow>
		)
	}

	let tableRows
	const rfqList = state?.rfqList

	const getFilteredRfqs = () => {
		let filterdRFQs = rfqList?.filter(rfq => !tagsToBeFiltered.includes(rfq?.Status))
		if (statusFilter !== '') {
			if (statusFilter === 'Review Pending') {
				filterdRFQs = filterdRFQs?.filter(rfq => rfq?.Status === 'UW:REVIEW:PENDING')
			} else {
				filterdRFQs = filterdRFQs?.filter(rfq => rfq?.Status !== 'UW:REVIEW:PENDING')
			}
		}
		return filterdRFQs
	}

	if (searchQuery?.length > 0 && rfqList?.length > 0) {
		let filterdRFQs = getFilteredRfqs()
		const filteredList = filterdRFQs?.filter(
			({ Name, Phone, id }) =>
				Name?.toLowerCase()?.includes(searchQuery.trim().toLowerCase()) ||
				Phone?.includes(searchQuery.trim()) ||
				id?.includes(searchQuery.trim())
		)

		if (filteredList?.length !== 0) {
			tableRows = !!loading?.status
				? []
				: filteredList?.map(rfqItem => {
						return getTableRow(rfqItem)
				  })
		}
	} else {
		if (rfqList?.length > 0) {
			let filterdRFQs = getFilteredRfqs()
			tableRows = !!loading?.status ? [] : filterdRFQs?.map(item => getTableRow(item))
		}
	}

	return (
		<>
			<div style={{ margin: '1rem' }} className="flex-container">
				<SearchField
					onChange={e => setSearchQuery(e.target.value)}
					placeholder="Search by RFQ ID, Name or Number"
				/>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<FilterButton
						disableLeftMargin
						activeFilter={statusFilter === '' ? 'Filter by Status' : statusFilter}
						setFilterValue={setFilterValue}
						menuItems={statusFilterMenuItems}
					/>
				</div>
			</div>
			<RFQTable hover borderless>
				<TableHeader>
					<tr>
						<th>CLIENT NAME</th>
						<th>NUMBER</th>
						<th>LAST UPDATED</th>
						<th>REVIEWED BY</th>
						<th>RFQ ID</th>
						<th>STATUS</th>
						<th></th>
					</tr>
				</TableHeader>
				<tbody>{tableRows}</tbody>
			</RFQTable>
		</>
	)
}

export default UnderwriterHome

const Tag = ({ status }) => {
	let bgColor, textColor

	if (status === 'Review Pending') {
		bgColor = `${theme.colors.secondaryLightRed}`
		textColor = `${theme.colors.red}`
	} else {
		bgColor = `${theme.colors.green2}`
		textColor = `${theme.colors.white}`
	}
	return <TagUI style={{ background: bgColor, color: textColor }}>{status}</TagUI>
}

// temporary tag component
const TagUI = styled.span`
	border: none;
	border-radius: 8px;
	padding: 0.625rem 1rem;
	font-size: 12px;
`

// table styles
const TableDataItem = styled.td`
	color: #02475e !important;
	font-size: 14px;
	font-weight: 700;
	vertical-align: middle;
	padding-left: 16px !important;
`

const TableRow = styled.tr`
	height: 55px;
	cursor: pointer;
	border-bottom: 1px solid #e5eaec;
	:hover {
		background-color: #ececec;
	}
`

const RFQTable = styled(Table)`
	background-color: #fff;
	border-radius: 5px;
	border-collapse: collapse;
	caption-side: bottom;
`

const TableHeader = styled.thead`
	background: #e5eaec;
	vertical-align: bottom;
	th {
		color: #8d8d8d !important;
		font-size: 12px;
		font-weight: 700;
		padding: 16px !important;
	}
`

const SearchField = styled(FormControl)`
	outline: none;
	border: none;
	background: #ffffff;
	padding: 8px;
	/* Card-New */
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	&:hover {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
	font-size: 1rem;
	font-weight: 400;
	color: #212529;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	width: 100%;
`
