import { useState } from 'react'
import Success from '../../components/Success'
import ReviewData from '../ReviewData'

const ReviewHandler = () => {
	const [pageToRender, setPageToRender] = useState('ReviewData')
	const displaySuccess = () => setPageToRender('ReviewSuccess')

	let renderCurrentPage = null
	if (pageToRender === 'ReviewData') {
		renderCurrentPage = <ReviewData displaySuccess={displaySuccess} />
	} else if (pageToRender === 'ReviewSuccess') {
		renderCurrentPage = <Success continueButtonLink="/" ctaText="Continue to Reviewing RFQ's" />
	}

	return renderCurrentPage
}

export default ReviewHandler
