import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router'
import { Container } from 'react-bootstrap'
import { Button } from 'verak-ui'

import UnderwriterHome from './UnderwriterHome'

const Home = () => {
	const { isLoading, isAuthenticated, loginWithPopup } = useAuth0()
	const history = useHistory()
	if (isLoading) {
		return (
			<div
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '3rem' }}
			>
				Loading...
			</div>
		)
	}

	if (!isAuthenticated) {
		return (
			<Container
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '3rem' }}
			>
				<Button onClick={loginWithPopup} label="Login to continue" />
			</Container>
		)
	} else {
		if (history.location.pathname === '/logout') {
			return <Redirect to="/" />
		}
		return <UnderwriterHome />
	}
}

export default Home
