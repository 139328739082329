import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { Button } from 'verak-ui'

import ConfirmationLottie from '../assets/confirmationLottie.svg'

const Success = ({ continueButtonLink, ctaText, children }) => {
	const history = useHistory()
	const handleContinueButtonClick = () => {
		history.push(continueButtonLink)
	}

	const ctaCopy = ctaText ? ctaText : 'Continue to Pre-Underwriting'

	return (
		<Container className="d-flex flex-column justify-content-center">
			<h5
				className="mt-5 mb-0"
				style={{
					fontSize: '24px',
					textAlign: 'center',
					fontWeight: 'bold',
				}}
			>
				Information submitted successfully
			</h5>
			<ConfirmationSuccessImage
				width="500"
				className="mx-auto"
				src={ConfirmationLottie}
				alt="confirmation-lottie"
				style={{ marginTop: '-2rem' }}
			/>
			<CustomDiv className="d-flex flex-column align-items-center">
				{/* anything children elements to render will appear here */}
				{children}
				<Button
					onClick={handleContinueButtonClick}
					label={ctaCopy}
					icon="rightArrow"
					iconAlign="right"
				/>
			</CustomDiv>
		</Container>
	)
}

export default Success

const lottieAnimation = keyframes`
    from{
        transform: scale(1);
    }
    to{
        transform: scale(.5);
    }
`

const fadeIn = keyframes`
    from{
        opacity: 0.3;
    }
    to{
        opacity: 1;
    }
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const CustomDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`
