import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'

import { Provider } from './context/Provider'

ReactDOM.render(
	<React.StrictMode>
		<Auth0ProviderWithHistory>
			<Provider>
				<App />
			</Provider>
		</Auth0ProviderWithHistory>
	</React.StrictMode>,
	document.getElementById('root')
)
